import { render, staticRenderFns } from "./gamedetail.vue?vue&type=template&id=05561ed7&scoped=true"
import script from "./gamedetail.vue?vue&type=script&lang=js"
export * from "./gamedetail.vue?vue&type=script&lang=js"
import style0 from "./gamedetail.vue?vue&type=style&index=0&id=05561ed7&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-load_7907ed7515d572276d45114f834f04a7/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05561ed7",
  null
  
)

export default component.exports